import React from 'react'
import { graphql } from 'gatsby'
import HeroComponent from '../components/HeroComponent/HeroComponent';
import SectionGrid from '../components/SectionGrid/SectionGrid';
import AlternateSection from '../components/AlternateSection/AlternateSection';
import SectionWithTextAndImage from '../components/SectionWithTextImage/SectionWithTextAndImage';
import Seo from '../components/seo';
import {useUrl} from '../fragments/hooks/useUrl'
const Home = ({
  location,
  pageContext,
  data: {
    contentfulPageHome:{
      slug,
      seoTitle,
      seoDescription,
      sections
    },
    allContentfulPageHome: {
        nodes
    }
  }
}) => {
  const lang = useUrl()
  
  return (
    <>
      <Seo
        slug={slug}
        title={seoTitle}
        description={seoDescription && seoDescription.seoDescription}
        node_locale={pageContext.node_locale}
        hrefLangs={nodes}
        content = {true}
        lang={lang}/>
        
      {
        sections.map((section:any, index: number) => {
          switch(section.__typename){
            case "ContentfulComponentHero":
              return (
                  <HeroComponent data={section} key={index} />
              )
              case "ContentfulComponentGridSection":
                return (
                  <SectionGrid data={section} key={index} />
                )
              case "ContentfulComponentAlternateSection":
              return (
                  <AlternateSection data={section} key={index} />
              )
              case "ContentfulSectionWithTextAndImage":
                return (
                  <SectionWithTextAndImage data={section} key={index} />
                )
          }
        })
      }
    </>
  )
}

export const HomeQuery = graphql`
    query HomeQuery( $id: String!, $contentful_id: String! ){
        contentfulPageHome(id: {eq: $id}){
            slug
            seoTitle
            seoDescription {
                seoDescription
            }
            sections {
                __typename
                ...componentHero
                ...contentfulComponentGridSection
                ...alternateSection
                ...sectionWithTextImage
            }
        }
        allContentfulPageHome(
            filter: {
                contentful_id: { eq: $contentful_id }
                id: { ne: $id }
                slug: { ne: null }
            }
        ) {
            nodes {
                slug
                node_locale
            }
        }
    }
  `
export default Home

