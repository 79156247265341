import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import Title from '../Shared/Title/Title'
import { Link } from 'gatsby'
import * as styles from './sectionWithTextImage.module.scss'
import { RichText } from '../Shared/RichText/RichText'
const SectionWithTextAndImage = ({data}) => {
    const{cta, description, image, backgroundImage, title,firstItalicContent,titleFontSize, titleColor} = data
    
  return (
    <div className='container'  style={{background: `url(${backgroundImage.file?.url}) no-repeat center center / cover`}}>
        <div className={styles.wrapper} >
            <div className={`container-lg `}>
                <div className={styles.contentWrapper}>
                        {title && <div style={{color: titleColor ? titleColor : '#ffffff'}} className={titleFontSize ? styles.cafe_title : styles.title}><Title title = {title} headingLevel = 'h2' firstItalicContent={firstItalicContent}/></div>}
                        <div className={styles.content}>
                            <div className={styles.descWrapper}>
                                <div className={styles.desc}>
                                    <div><RichText content={description} /></div>
                                    {cta && <button className= {`red-button ${styles.cta}`}>{ cta.externalLink ? <a href={cta.externalLink} target='_blank'> {cta.title} </a> : <Link to = {cta.slug}> {cta.title}</Link>}</button>}
                                </div>
                            </div>
                            <div className={styles.image}>
                                <GatsbyImage
                                    image={image.gatsbyImageData}
                                    alt={`${image.title} image`}
                                    loading="lazy"/>
                            </div>
                        </div>
                </div>
            </div>
            <div className={styles.mobileWrapper}>
                    <div>
                        <div>
                            <GatsbyImage
                                image={image.gatsbyImageData}
                                alt={`${image.title} image`}
                                loading="lazy"/>
                        </div>
                        <div className={styles.mobileTitle}>
                            {title && <div className={titleFontSize ? styles.cafe_title : styles.title}><Title title = {title} headingLevel = 'h2'/></div>}
                        </div>
                        <div className={styles.descWrapper}>
                            <div className={styles.desc}>
                                <div><RichText content={description} /></div>
                                {cta && <button className= {`red-button ${styles.cta}`}>{ cta.externalLink ? <a href={cta.externalLink}> {cta.title} </a> : <Link to = {cta.slug}> {cta.title}</Link>}</button>}
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
  )
}

export default SectionWithTextAndImage