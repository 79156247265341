// extracted by mini-css-extract-plugin
export var backgroundImg = "sectionWithTextImage-module--background-img--cabe4";
export var button = "sectionWithTextImage-module--button--68e3f";
export var cafeFormIntro = "sectionWithTextImage-module--cafe-form-intro--96e61";
export var cafe_title = "sectionWithTextImage-module--cafe_title--ecaed";
export var content = "sectionWithTextImage-module--content--d9b2a";
export var contentWrapper = "sectionWithTextImage-module--content-wrapper--05aab";
export var cta = "sectionWithTextImage-module--cta--df429";
export var desc = "sectionWithTextImage-module--desc--87392";
export var descWrapper = "sectionWithTextImage-module--desc-wrapper--98116";
export var highlight = "sectionWithTextImage-module--highlight--19707";
export var image = "sectionWithTextImage-module--image--80b1a";
export var mobileTitle = "sectionWithTextImage-module--mobile-title--88b81";
export var mobileWrapper = "sectionWithTextImage-module--mobile-wrapper--7bd26";
export var redButton = "sectionWithTextImage-module--red-button--bd5d5";
export var redTransparentButton = "sectionWithTextImage-module--red-transparent-button--f02c1";
export var title = "sectionWithTextImage-module--title--c648c";
export var transparentButton = "sectionWithTextImage-module--transparent-button--391c7";
export var wrapper = "sectionWithTextImage-module--wrapper--d0eea";